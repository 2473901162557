<template>
  <div class="order-detail-page">
     <Breadcrumb style="margin-bottom: 20px">
      <BreadcrumbItem to="/user/purchase">我的订单</BreadcrumbItem>
      <BreadcrumbItem>订单详情</BreadcrumbItem>
    </Breadcrumb>
    <!-- 订单商品信息 -->
    <DetailOrderGoods :orderDetail="orderDetail" />
  </div>
</template>
<script>
import useOrderDetail from '@/hooks/member/useOrderDetail'
import DetailOrderGoods from '@/views/member/order/purchase/components/DetailOrderGooods'
export default {
  name: 'PurchaseOrderDetailPage',
  components: {
    DetailOrderGoods,

  },
  setup () {
    const { orderDetail, getData } = useOrderDetail()

    return { orderDetail, getData }
  },
}
</script>
<style scoped lang="less">
.order-detail-page {
  background: #fff;
  height: 100%;
}
</style>
