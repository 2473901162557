<template>
  <div class="order-info">
    <h3 style="margin-bottom: 20px">订单详情</h3>
    <div class="steps">
      <Steps :current="stepsNum">
        <Step title="确认订单" :content="orderStatus[0].createDate"></Step>
        <Step title="签合同" :content="orderStatus[1].createDate"></Step>
        <Step title="付款" :content="orderStatus[2].createDate"></Step>
        <Step title="待收货" :content="orderStatus[3].createDate"></Step>
        <Step title="完成交易" :content="orderStatus[4].createDate"></Step>
      </Steps>
    </div>
    <Alert type="warning" show-icon class="alert">
      请认真核对双方交易内容及其相关的附件资料，如经确认，即表示您已同意详情中的相关信息
    </Alert>
    <!-- 商品列表 -->
    <!-- <h3>订单商品</h3> -->
    <div class="title">
      <span class="icon"></span>
      <span class="text">订单信息&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span class="">订单号：{{ orderInfo.tradeId }}</span>
    </div>

    <div class="info-box">
      <h4>供应商信息</h4>
      <dl>
        <dt>供应商:</dt>
        <dd>
          {{ supplier.companyName }}
        </dd>
        <dt>手机号：</dt>
        <dd>
          {{ supplier.phone }}
        </dd>
      </dl>
      <dl>
        <dt>收货单位：</dt>
        <dd>{{ supplier.companyName }}</dd>
        <dt>固定电话：</dt>
        <dd>{{ supplier.tel }}</dd>
      </dl>
      <dl>
        <dt>地址：</dt>
        <dd>{{ supplier.address }}</dd>
        <dt>邮箱地址：</dt>
        <dd>{{ supplier.email }}</dd>
      </dl>
    </div>
    <!-- 支付方式及送货时间 -->
    <!-- <h3>支付方式及送货时间</h3> -->
    <div class="info-box">
      <h4>配送信息</h4>
      <dl>
        <dt>配送方式：</dt>
        <dd>上门自提</dd>
        <dt>发货要求：</dt>
        <dd>款到即发</dd>
        <!-- <dt>运费方式：</dt>
        <dd></dd> -->
      </dl>
      <dl>
        <dt>自提地址：</dt>
        <dd>{{ supplier.sendFromCityCode }}</dd>
      </dl>
    </div>
    <!-- 发票信息 -->
    <!-- <h3>发票信息</h3> -->
    <div class="info-box">
      <h4>支付信息</h4>
      <dl>
        <dt>付款方式：</dt>
        <dd>平台转账</dd>
        <!-- <dt>付款时间：</dt>
        <dd></dd> -->
      </dl>
    </div>
    <!-- 商品信息 -->
    <div class="title">
      <span class="icon"></span>
      <span class="text">商品信息</span>
      <!-- <span
        v-if="isEdit"
        style="float: right; color: #409eff"
        @click="save(false)"
      >
        保存
      </span>
      <span v-else style="float: right; color: #409eff" @click="addRow(true)">
        编辑
      </span> -->
    </div>

    <div class="info-box-noborder">
      <Table border :columns="columns" :data="goodsData"></Table>
    </div>

    <!-- 合同信息 -->
    <div
      v-if="stepsNum >= 1 && orderInfo.paymentOrder.contractUrl"
      class="title"
    >
      <span class="icon"></span>
      <span class="text">合同信息</span>
    </div>
    <div
      v-if="stepsNum >= 1 && orderInfo.paymentOrder.contractUrl"
      class="info-box-noborder"
    >
      <dl>
        <dt>付款方式</dt>
        <dd>先款后货</dd>
      </dl>

      <dl>
        <dt>合同文件</dt>
        <dd>
          <div
            v-for="(item, index) in orderInfo.paymentOrder.contractUrl"
            :key="index"
          >
            <Icon type="ios-paper-outline" />
            <span>
							附件{{index+1}}
              <!-- {{ orderInfo.paymentOrder.contractCode }}合同.jpg -->
              <!--  v-down="orderInfo.paymentOrder.contractUrl" -->
              <a
                class="down-img-text"
                style="margin-left: 10px"
                @click="open(orderInfo.paymentOrder.contractUrl[index])"
              >
                查看
              </a>
            </span>
          </div>
        </dd>
      </dl>
      <dl>
        <dt>备注说明</dt>
        <dd>
          {{ orderInfo.paymentOrder.contractMemo }}
        </dd>
      </dl>
    </div>

    <!-- 仓单信息 -->
    <div v-if="currentStatus > 4 && currentStatus != 7" class="title">
      <span class="icon"></span>
      <span class="text">仓单信息</span>
    </div>
    <div v-if="stepsNum >= 3" class="info-box-noborder">
      <dl>
        <dt>仓单文件</dt>
        <dd>
          <div v-if="orderInfo.paymentOrder.warehouseOrderUrl">
            <Icon type="ios-paper-outline" />
            <span>
              仓单.jpg
              <a
                class="down-img-text"
                style="margin-left: 10px"
                @click="open(orderInfo.paymentOrder.warehouseOrderUrl)"
              >
                查看
              </a>
            </span>
          </div>
        </dd>
      </dl>
      <dl>
        <dt>备注说明</dt>
        <dd>
          {{ orderInfo.paymentOrder.warehouseOrderMemo }}
        </dd>
      </dl>
    </div>

    <!-- 发票信息 -->
    <div v-if="stepsNum >= 3 && currentStatus != 7" class="title">
      <span class="icon"></span>
      <span class="text">发票信息</span>
    </div>
    <div v-if="stepsNum >= 3" class="info-box-noborder">
      <dl>
        <dt>发票文件</dt>
        <dd>
          <div v-if="orderInfo.paymentOrder.payBillUrl">
            <Icon type="ios-paper-outline" />
            <span>
              发票.jpg
              <a
                class="down-img-text"
                style="margin-left: 10px"
                @click="open(orderInfo.paymentOrder.payBillUrl)"
              >
                查看
              </a>
            </span>
          </div>
        </dd>
      </dl>
      <dl>
        <dt>备注说明</dt>
        <dd>
          {{ orderInfo.paymentOrder.pauBillMemo }}
        </dd>
      </dl>
    </div>

    <!-- 汇总信息 -->
    <div class="sum-info">
      <div v-if="stepsNum == 0" class="info">
        <div class="item">
          <label>商品总金额</label>

          <span class="price">
            ¥
            <Numeral :value="orderAmount" format="0,0.00"></Numeral>
          </span>
        </div>
      </div>
      <div v-if="stepsNum == 2 || stepsNum == 4" class="info">
        <div class="item">
          <label>商品总金额</label>
          <span>
            ¥
            <Numeral :value="orderAmount" format="0,0.00"></Numeral>
          </span>
        </div>
        <div class="item">
          <label>支付金额(元)</label>
          <span class="price">
            ¥
            <Numeral :value="orderAmount" format="0,0.00"></Numeral>
          </span>
        </div>
      </div>
      <div v-if="stepsNum == 3" class="info">
        <div class="item">
          <label>商品总金额</label>
          <span>
            ¥
            <Numeral :value="orderAmount" format="0,0.00"></Numeral>
          </span>
        </div>
      </div>
    </div>
    <!-- 确认订单  -->
    <!-- <div v-if="stepsNum == 0" class="sum-info">
      <Button class="btn" @click="submit(0)">确认订单</Button>
      <span class="hint">
        温馨提示：请仔细阅读合同，确认交易双方安全。保证资金账户安全
      </span>
    </div> -->
    <div
      v-if="currentStatus === 2 && orderInfo.paymentOrder.contractUrl"
      class="sum-info"
    >
      <Button class="btn" @click="submit(1)">确认合同</Button>
      <span class="hint">
        温馨提示：请仔细阅读合同，确认交易双方安全。保证资金账户安全
      </span>
    </div>
    <div v-if="currentStatus == 3" class="sum-info">
      <Button class="btn" @click="submit(2)">立即支付</Button>
      <span class="hint">
        温馨提示：请仔细阅读合同，确认交易双方安全。保证资金账户安全
      </span>
    </div>
    <div v-if="stepsNum == 3" class="sum-info">
      <Button class="btn" @click="submit(3)">确认到货</Button>
      <span class="hint">
        温馨提示：请仔细阅读合同，确认交易双方安全。保证资金账户安全
      </span>
    </div>
  </div>
</template>

<script>
import { fileUploadLimit } from '@/utils/request.js'
import { ref } from 'vue'
export default {
  name: 'DetailOrderGoods',
  props: {},
  data () {
    return {
      isEdit: false,
      currentStatus: 0, // 订单当前状态
      tradeTypeList: [
        {
          value: '全款模式',
          label: '全款模式',
        },
        {
          value: '保证金模式',
          label: '保证金模式',
        },
      ],
      columns: [],
      orderInfo: {}, // 订单数据
      orderStatus: [
        { createDate: '' },
        { createDate: '' },
        { createDate: '' },
        { createDate: '' },
        { createDate: '' },
      ],
      productList: [],
      purchaserInfo: {}, // 采购商信息
      supplier: {}, // 供应商信息
      goodsData: [],
      orderAmount: '', // 总金额
      stepsNum: 0,
    }
  },
  created () {
    this.init()
  },

  methods: {
    init () {
      const params = {
        id: this.$route.params.id,
      }
      this.$store
        .dispatch('PaymentPaymentOrder', params)
        .then((res) => {
          if (res.returnCode === '1') {
            this.goodsData = []
            this.orderInfo = res.result
            this.purchaserInfo = res.result.paymentOrder
            this.supplier = res.result.supplier

            // Steps 数据
            this.stepsNum = res.result.orderStatus.length - 1
            this.currentStatus = res.result.paymentOrder.orderStatus
            //  this.stepsNum = 2
            const arr = ref([
              { createDate: '' },
              { createDate: '' },
              { createDate: '' },
              { createDate: '' },
              { createDate: '' },
            ])
            res.result.orderStatus.forEach(function (element, index) {
              arr.value.splice(index, 1, element)
            })
            this.orderStatus = arr.value
          }
        })
        .then(() => {
          this.save()
        })
    },

    save (isEdit) {
      this.isEdit = isEdit
      this.goodsData = []
      this.columns = [
        {
          title: '品名',
          key: 'goodsName',
          width: 150,
        },
        {
          title: '规格',
          key: 'formate',
          width: 150,
        },
        {
          title: '材质',
          key: 'material',
          width: 100,
        },
        {
          title: '数量/重量',
          key: 'goodsCount',
          width: 100,
        },
        {
          title: '单价',
          key: 'price',
          width: 120,
        },
        {
          title: '单位',
          key: 'unitName',
          width: 120,
        },
        {
          title: '交易模式',
          key: 'tradeType',
          className: 'table-info-column',
        },
        // {
        //   title: '货期',
        //   key: 'deliveryDate',
        //   width: 120,
        // },
        // {
        //   title: '仓库',
        //   key: 'sendFromCityCode',
        //   width: 100,
        // },
        // {
        //   title: '小计',
        //   key: 'activePrice',

        //   fixed: 'right',
        //   width: 120,
        // },
      ]
      this.orderInfo.paymentOrder.tradeType === 2
        ? (this.orderInfo.paymentOrder.tradeType = '保证金模式')
        : (this.orderInfo.paymentOrder.tradeType = '全款模式')

      this.goodsData.push(this.orderInfo.paymentOrder)
      this.orderAmount = this.orderInfo.paymentOrder.orderAmount
    },
    contractUpload (file) {
      const fileValide = fileUploadLimit(file, this.fileLimitSize)
      if (fileValide) {
        this.$Message.error(fileValide)
        return false
      }
      return true
    },

    contractSuccess (response, file, fileList) {
      if (response.returnCode === '1') {
        this.formValidate.certificateImg.push(response.result)
        // this.$refs.formValidate.validateField('logo')
      }
    },

    contractRemove () {
      this.formValidate.logo = ''
      this.$refs.formValidate.validateField('logo')
    },
    open (url) {
      window.open(url, '_self')
    },
    submit (num) {
      switch (num) {
        // 确认 我的提单
        case 0: {
          const postData = ref({
            id: this.orderInfo.paymentOrder.id,
            tradeId: this.orderInfo.tradeId,
          })
          this.$store
            .dispatch('PaymentFirmPaymentOrder', postData.value)
            .then((res) => {
              if (res.returnCode === '1') {
                this.$Notice.success({
                  title: '确认订单成功',
                })
              }
            })
            .then(() => {
              this.save()
            })
          break
        }
        // 确认合同
        case 1: {
          const postData = ref({
            id: this.orderInfo.paymentOrder.id,
            tradeId: this.orderInfo.tradeId,
            // tradeType: '',
          })

          this.$store
            .dispatch('PaymentConfirmContrac', postData.value)
            .then((res) => {
              if (res.returnCode === '1') {
                this.$router.push('/user/purchase')
                this.$Notice.success({
                  title: '确认确认合同成功',
                })
              }
            })
            .then(() => {
              this.save()
            })
          break
        }
        // 立即支付
        case 2: {
          const postData = ref({
            id: this.orderInfo.paymentOrder.id,
            tradeId: this.orderInfo.tradeId,
          })
          this.$Spin.show({
            render: (h) => {
              return h('div', [
                h(
                  'div',
                  {
                    style: {
                      color: '#d12b23',
                      fontSize: '22px',
                    },
                  },

                  '正在启动银行支付请不要离开页面。否则无法完成支付...',
                ),
              ])
            },
          })
          this.$store
            .dispatch('PaymentPayImmediately', postData.value)
            .then((res) => {
              if (res.returnCode === '1') {
                window.open(res.result.authUrl, '_self')
                // this.$Spin.hide()
                this.$Notice.success({
                  title: '操作成功',
                })
              }
            })
            .then(() => {
              this.save()
            })
          break
        } // 确认收货
        case 3: {
          const postData = ref({
            id: this.orderInfo.paymentOrder.id,
            tradeId: this.orderInfo.tradeId,
          })

          this.$store
            .dispatch('PaymentConfirmGoods', postData.value)
            .then((res) => {
              if (res.returnCode === '1') {
                this.$router.push('/user/purchase')
                this.$Notice.success({
                  title: '确认收货',
                })
              }
            })
            .then(() => {
              this.save()
            })
          break
        }

        default:
        //
      }
    },
  },
}
</script>

<style scoped lang="less">
.order-info {
  padding: 0 10px;
  // h3 {
  //   font-weight: normal;
  //   font-size: 16px;
  //   padding: 25px 5px;
  // }
  .title {
    height: 20px;
    line-height: 1;
    margin-bottom: 10px;
    padding: 25px 5px;
    .icon {
      display: inline-block;
      height: 20px;
      width: 5px;
      background-color: #d12b23;
      margin-right: 10px;
      vertical-align: middle;
    }
    .text {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
// 商品表格
.goods-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto;
  th {
    font-weight: normal;
    line-height: 60px;
    background: #f5f5f5;
    &:first-child {
      text-align: left;
      padding-left: 20px;
    }
  }
  td {
    border-bottom: 1px solid #f5f5f5;
    text-align: center;
    &:first-child {
      padding-left: 20px;
      border-left: 1px solid #f5f5f5;
    }
    &:last-child {
      border-right: 1px solid #f5f5f5;
    }
  }
  .product {
    display: flex;
    padding: 20px 0;
    img {
      width: 70px;
      height: 70px;
      border: 1px solid #f5f5f5;
    }
    .info {
      align-self: center;
      padding-left: 20px;
      text-align: left;
      p {
        margin-bottom: 5px;
        width: 280px;
      }
      .attrs {
        color: #999;
      }
    }
  }
}
// 信息盒子
.info-box {
  border: 1px solid #dcdfe6;
  padding: 10px;
  margin-bottom: 10px;
  dl {
    display: flex;
    line-height: 30px;
    dt {
      width: 80px;
      color: #999;
      font-size: 14px;
      i {
        display: inline-block;
        width: 0.5em;
      }
    }
    dd {
      flex: 1;
    }
  }
}
.info-box-noborder {
  padding: 10px;
  margin-bottom: 10px;
  dl {
    display: flex;
    line-height: 30px;
    dt {
      width: 80px;
      color: #999;
      font-size: 14px;
      i {
        display: inline-block;
        width: 0.5em;
      }
    }
    dd {
      flex: 1;
    }
  }
  .decs-img-text {
    font-size: 12px;
    color: #909399;
  }
  .down-img-text {
    font-size: 14px;
    color: #409eff;
  }
}
// 汇总信息
.sum-info {
  // padding: 0 30px 10px 30px;
  overflow: hidden;

  .hint {
    float: right;
    text-align: center;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: #dc9100;
  }
  .btn {
    float: right;
    width: 134px;
    background: #d12b23;
    height: 37px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border: 1px solid #cfcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info {
    width: 380px;
    // height: 160px;
    float: right;
    font-size: 14px;

    .item {
      line-height: 40px;
      display: flex;
      label {
        text-align: right;
        width: 160px;
        // font-size: 14px;
        i {
          display: inline-block;
          width: 2em;
        }
      }
      span {
        flex: 1;
        text-align: right;
        padding-right: 30px;
        &.price {
          font-size: 20px;
          color: @priceColor;
        }
      }
    }
  }
}
.steps {
  height: 73px;
  background: #f8f8fb;
  padding: 20px;
}
.alert {
  margin-top: 10px;
  background: #faecd8;
  color: #dc9100;
  font-size: 13px;
  height: 52px;
  display: flex;
  align-items: center;

  // justify-content: center;
}
/deep/ .ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner {
  background: #d12b23;
  border-color: #d12b23;
}
/deep/ .ivu-steps .ivu-steps-title {
  background: #f8f8fb;
}
/deep/ .ivu-steps .ivu-steps-head {
  background: #f8f8fb;
}
/deep/ .ivu-upload {
  padding: 10px 20px 0 0;
}
/deep/ .ivu-table td.table-info-column {
  color: #d12b23;
}
/deep/ .ivu-alert-icon {
  margin-top: 10px;
}
/deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail > i:after {
  background: #d12b23;
}
/deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner {
  border-color: #d12b23;
}
/deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner span,
.ivu-steps-item.ivu-steps-status-finish
  .ivu-steps-head-inner
  > .ivu-steps-icon {
  color: #d12b23;
}
/deep/ .ivu-steps-item.ivu-steps-status-process .ivu-steps-title {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
/deep/ .ivu-steps-item.ivu-steps-status-wait .ivu-steps-title {
  color: #999999;
  font-size: 16px;
}
/deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-title {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
</style>
